<template>
  <div class="systemInfo wr">
    <div class="info-a wr f-cb">
      <div class="left">
        <div class="pinfo-container wr swiper-container-horizontal swiper-container-android">
        <div class="swiper-wrapper">
        <div class="swiper-slide swiper-slide-active">
          <img src="../../../img/controlSystem.jpg" alt="">
        </div>
        </div>
        </div>
        </div>
        <div class="right ">
        <div class="tit-60 mc hnb">无人值守泵站控制系统</div>
        <div class="tit-18 t1 hnb">
        <p style="line-height: 1.75em; text-align: justify;">
          <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
            无人值守泵站控制系统是一种自动化供水解决方案，旨在提高泵站的运行效率、可靠性和安全性。通过集成的监控技术和自动控制系统，
            该系统能够实现全天候自动运行，无需人工操作，从而有效降低运营成本并提升服务质量。
          </span>
        </p>
        <p style="line-height: 1.75em;"><br></p>
        </div>
      </div>
    </div>
    <div class="view">
      <div class="info-b wr">
        <div class="tit-48 mc lh1 hnb">产品特点</div>
        <div class="list f-cb">
          <div class="li"> 
            <div>
              <div class="icon">
                <img :src="require('@/img/productFeatures-icon.png')" alt="自动调控操作">
              </div>
              <div class="tit-24 t1 hnb">自动调控操作</div>
            </div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  系统实时监控水压、流量等关键参数，自动调节水泵启停与运行速度，以满足不同的供水需求，确保高效运行。
                </span>
              </p>
            </div>
          </div>
          <div class="li">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="故障智能诊断与响应">
            </div>
            <div class="tit-24 t1 hnb">故障智能诊断与响应</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  系统实时诊断故障（如水泵堵塞、机械故障等），自动预警并采取应对措施（如切换备用泵），确保供水连续性。
                </span>
              </p>
            </div>
          </div>
          <div class="li">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="远程监控与管理">
            </div>
            <div class="tit-24 t1 hnb">远程监控与管理</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  管理人员可以远程访问泵站控制系统，实时监控并进行必要调整，提升管理效率，减少对现场操作人员的依赖。
                </span>
              </p>
            </div>
          </div>
          <div class="li">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="能源管理与优化">
            </div>
            <div class="tit-24 t1 hnb">能源管理与优化</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  系统优化泵的运行计划和速度，减少能源损耗。  通过数据分析预测最佳维护时间，减少非计划停机，进一步降低运营成本。
                </span>
              </p>
            </div>
          </div>
          <div class="li">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="数据集成与报告">
            </div>
            <div class="tit-24 t1 hnb">数据集成与报告</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  系统整合操作数据与性能指标，自动生成报告，帮助技术团队与管理层了解泵站的运行状况与效率。报告内容包括水质分析、能耗统计、预防性维护记录等，为决策提供数据支持，优化长期运营与维护策略。
                </span>
              </p>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        
    },
    mounted() {
        
    }
}
</script>
<style lang="less" scoped>
.mc {
    color: #0041c3;
}
.lh1 {
    line-height: 1;
}
.tit-48 {
    line-height: 1.8;
    font-size: 48px;
}
// web端样式
.systemInfo {
  background-image: url(../../../img/bg3.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  // padding-top: 100px;
  padding-bottom: 4.5rem;
  .info-a {
    padding-top: 7.38rem;
    padding-bottom: 6rem;
    padding-left: 9rem;
    .left {
      float: left;
      width: 44.8%;
      .pinfo-container {
          overflow: hidden;
      }
    }
    .right {
        float: left;
        width: 55.2%;
        padding: 32px 190px 0 89px;
        // padding-left: 2.225rem;
        // padding-right: 4.75rem;
        // padding-top: .8rem;
      .mc {
        border-bottom: 1px solid rgba(0, 65, 195, .2);
        line-height: 1;
        padding-bottom: 2.5rem;
        position: relative;
      }
      .mc::after {
        content: "";
        width: 60px;
        height: 6px;
        background: #0041C3;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      .t1 {
        color: #000000;
        margin-top: 3.31rem;
      }
    }
  }
  .view {
    width: 100%;
    padding: 0 190px;
    .info-b {
      background-color: #fff;
      padding: 76px 118px;
      margin-top: 20px;
      .list {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        .li {
            width: calc(39.5%);
            // width: calc((100% - 115px) / 2);
            margin-right: 115px;
            padding: 40px 0;
            border-bottom: 1px solid rgba(207, 214, 229, 1);
            .icon {
              display: inline-block;
              vertical-align: middle;
              margin-bottom: 0.63rem;
            }
            .t1 {
                display: inline-block;
                margin-top: 1.25rem;
                margin-bottom: 1rem;
                margin-left: 0.8rem;
                position: relative;
            }
        }
      }
    }
  }
}
// 移动端样式
@media screen and (max-width:650px) {
    .home-bg1 {
        margin-top: -44px;
        .cycle-wrapper {
            padding: 44px 0px;
            .content {
                padding: 5px;
                margin: 0 35px;
                .con-title {
                    font-size: 14px;
                }
                .con-text {
                    font-size: 14px;
                    line-height: 35px;
                }
            }
        }
    }
}
</style>
<style>
/* 移动端样式 */
@media screen and (max-width:650px) {
    .el-breadcrumb__inner {
        font-size: 14px !important;
    }
    .el-breadcrumb__separator {
        font-size: 14px !important;
    }
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"systemInfo wr"},[_vm._m(0),_c('div',{staticClass:"view"},[_c('div',{staticClass:"info-b wr"},[_c('div',{staticClass:"tit-48 mc lh1 hnb"},[_vm._v("产品特点")]),_c('div',{staticClass:"list f-cb"},[_c('div',{staticClass:"li"},[_c('div',[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"自动调控操作"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("自动调控操作")])]),_vm._m(1)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"故障智能诊断与响应"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("故障智能诊断与响应")]),_vm._m(2)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"远程监控与管理"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("远程监控与管理")]),_vm._m(3)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"能源管理与优化"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("能源管理与优化")]),_vm._m(4)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"数据集成与报告"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("数据集成与报告")]),_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-a wr f-cb"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"pinfo-container wr swiper-container-horizontal swiper-container-android"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide swiper-slide-active"},[_c('img',{attrs:{"src":require("../../../img/controlSystem.jpg"),"alt":""}})])])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"tit-60 mc hnb"},[_vm._v("无人值守泵站控制系统")]),_c('div',{staticClass:"tit-18 t1 hnb"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 无人值守泵站控制系统是一种自动化供水解决方案，旨在提高泵站的运行效率、可靠性和安全性。通过集成的监控技术和自动控制系统， 该系统能够实现全天候自动运行，无需人工操作，从而有效降低运营成本并提升服务质量。 ")])]),_c('p',{staticStyle:{"line-height":"1.75em"}},[_c('br')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 系统实时监控水压、流量等关键参数，自动调节水泵启停与运行速度，以满足不同的供水需求，确保高效运行。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 系统实时诊断故障（如水泵堵塞、机械故障等），自动预警并采取应对措施（如切换备用泵），确保供水连续性。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 管理人员可以远程访问泵站控制系统，实时监控并进行必要调整，提升管理效率，减少对现场操作人员的依赖。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 系统优化泵的运行计划和速度，减少能源损耗。 通过数据分析预测最佳维护时间，减少非计划停机，进一步降低运营成本。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 系统整合操作数据与性能指标，自动生成报告，帮助技术团队与管理层了解泵站的运行状况与效率。报告内容包括水质分析、能耗统计、预防性维护记录等，为决策提供数据支持，优化长期运营与维护策略。 ")])])])
}]

export { render, staticRenderFns }